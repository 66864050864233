/**
 * @created by Zheko Hristov on 01/10/2021
 * @last-updated by Zheko Hristov on 03/11/2021
 *
 * Forms validate scripts
 **/

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        // basic regex patterns for input and textarea validation, based on the field 'data-validate-type' attribute
        const regexInputPatterns = {
            user_name: /^[a-zа-я]{1}[a-zа-я\s-]*$/i,
            user_telephone: /^[\+]?\d{10,}$/,
            user_email: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            user_message: /\S+/,
            user_city: /^[a-zа-я]{1}[a-zа-я\s]+$/i,
            user_address: /\S+/,
            user_address_office: /\S+/,
            user_password: /^.{8,}$/,
            user_password_confirmation: /^.{8,}$/,
            user_current_password: /^.{8,}$/,
        };

        // validation function with regex
        // for toggling 'field--valid' and 'field--valid' classes on the fields
        function validateField(field, regex) {
            if (regex.test(field.val())) {
                field.removeClass('field--invalid');
                field.addClass('field--valid');
            } else {
                field.removeClass('field--valid');
                field.addClass('field--invalid');
            }

            // confirm password check
            // - if the value of the password input is valid and it is equal to the value of the confirm password input
            // then trigger 'field--valid' and 'field--invalid' classes for the confirm password input
            if (field.attr('data-validate-type') === 'user_password') {
                const confirmPasswordInput = $(
                    'input[data-validate-type="user_password_confirmation'
                );
                if (confirmPasswordInput.val()) {
                    if (regex.test(field.val()) && field.val() === confirmPasswordInput.val()) {
                        confirmPasswordInput.removeClass('field--invalid');
                        confirmPasswordInput.addClass('field--valid');
                    } else {
                        confirmPasswordInput.removeClass('field--valid');
                        confirmPasswordInput.addClass('field--invalid');
                    }
                }
            }

            // confirm password check
            // - if the value of the confirm password input is valid and it is equal to the value of the password input
            // then trigger 'field--valid' and 'field--invalid' classes for the confirm password input
            if (field.attr('data-validate-type') === 'user_password_confirmation') {
                const passwordInput = $('input[data-validate-type="user_password"]');
                if (regex.test(field.val()) && field.val() === passwordInput.val()) {
                    field.removeClass('field--invalid');
                    field.addClass('field--valid');
                } else {
                    field.removeClass('field--valid');
                    field.addClass('field--invalid');
                }
            }
        }

        // execute functions for inputs, which has to be validated
        $('.js-input-validate').on('input', function () {
            const thisInput = $(this);
            const thisInputValidateType = thisInput.attr('data-validate-type');
            validateField(thisInput, regexInputPatterns[thisInputValidateType]);
        });

        // contacts page form disable/enable 'send message button'
        $('.contacts__form__container .js-agree-terms').on('change', function () {
            const contactsCheckboxContainer = $(this);
            const contactsButtonContainer = $('.input__container--single button');
            if (contactsCheckboxContainer.is(':checked')) {
                contactsButtonContainer.removeClass('disabled');
                contactsButtonContainer.attr('tabindex', '0');
                contactsButtonContainer.removeAttr('disabled');
            } else {
                contactsButtonContainer.addClass('disabled');
                contactsButtonContainer.attr('tabindex', '-1');
                contactsButtonContainer.prop('disabled', true);
            }
        });
    })();
});
