/**
 * @created by Plamena Gancheva on 26/01/2022
 * @last-updated by Zheko Hristov on 02/02/2022
 *
 * Social media and print scripts
 **/

var socialMedia = (function ($) {
    function init() {
        bindSocialMediaShareButtons();
    }

    function bindSocialMediaShareButtons() {
        // bind twitter
        $('.js-twitter-share').on('click', function () {
            var maxTextLength = 270;

            var text = $(this).data('text') || '';
            var shareUrl = $(this).data('url') || window.location.href;

            maxTextLength = Math.max(maxTextLength - shareUrl.length, 0);

            var encodedUrl = encodeURI(shareUrl);
            var encodedText = '';
            if (text.length > 0) {
                encodedText = encodeURI(text.substr(0, maxTextLength - 1));
            }
            var twitterLink = 'http://twitter.com/share?url=' + encodedUrl;
            if (encodedText.length > 0) {
                twitterLink = twitterLink + '&text=' + encodedText;
            }

            //	console.log(twitterLink);
            window.open(twitterLink, '_blank');
        });

        // bind facebook
        $('.js-fb-share').on('click', function () {
            // var text = $(this).data('text') || '';
            var shareUrl = $(this).data('url') || window.location.href;

            var encodedUrl = encodeURI(shareUrl);

            var facebookLink =
                'https://www.facebook.com/sharer/sharer.php?display=popup&u=' + encodedUrl;

            //	console.log(twitterLink);
            window.open(
                facebookLink,
                'sharer',
                'toolbar=0,status=0,resizable=1,width=626,height=436'
            );
        });

        // bind print
        $('.js-print').on('click', function () {
            window.print();
        });
    }

    return {
        init: init,
    };
})(jQuery);

export default socialMedia;
