/**
 * @created by Plamena Gancheva on 08/11/2021
 * @last-updated by Zheko Hristov on 14/11/2023
 *
 * Sliders scripts
 **/

var sliderScripts = (function ($) {
    'use strict';
    function init() {
        bindHomeSlider();
        bindSubcategoriesSliders();
    }

    function destroy() {
        $('.home-slider__container--inner').owlCarousel('destroy');
        $('.home__container--single-category-subcategories').owlCarousel('destroy');
    }

    function bindHomeSlider() {
        initializeHomePageTopSlider();

        // initialize home page top container slaider
        function initializeHomePageTopSlider() {
            $('.home-slider__container--inner').owlCarousel({
                loop: true,
                items: 3,
                margin: 15,
                stagePadding: 15,
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                nav: true,
                dots: false,
                responsive: {
                    0: {
                        items: 1,
                    },
                    421: {
                        items: 2,
                    },
                    601: {
                        margin: 30,
                    },
                    1600: {
                        stagePadding: 18,
                        margin: 36,
                    },
                    1900: {
                        stagePadding: 21,
                        margin: 42
                    },
                },
            });
        }

        // reset owl carousel autoplayTimeout after user action
        $('.home-slider__container--inner').on('changed.owl.carousel', function () {
            $('.home-slider__container--inner').trigger('stop.owl.autoplay');
            $('.home-slider__container--inner').trigger('play.owl.autoplay');
        });
    }

    function bindSubcategoriesSliders() {
        initializeHomePageSubcategoriesSliders();

        // initialize home page top container slaider
        function initializeHomePageSubcategoriesSliders() {
            $('.home__container--single-category-subcategories').owlCarousel({
                loop: false,
                margin: 20,
                autoplay: false,
                nav: true,
                dots: false,
                responsive: {
                    0: {
                        items: 1,
                    },
                    376: {
                        items: 2,
                        margin: 10,
                    },
                    421: {
                        items: 2,
                    },
                    601: {
                        items: 3,
                    },
                    801: {
                        items: 4,
                    },
                    1025: {
                        items: 5,
                    },
                },
            });
        }
    }

    return {
        init: init,
        destroy: destroy,
    };
})(jQuery);

export default sliderScripts;
